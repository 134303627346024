import { makeStyles } from '@material-ui/core'

export const style = makeStyles(() => ({
  candidateData: {
    top: '0',
    right: '25px',
    width: '23rem',
    height: '100%',
    padding: '80px 2rem',
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    overflowY: 'auto',
  },

  candidateDataAlternative: {
    top: '0',
    right: '25px',
    width: '30rem',
    height: '100%',
    padding: '80px 2rem',
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    overflowY: 'auto',
  },

  colorTextStatus: {
    textAlign: 'center',
    padding: 6,
    borderRadius: 6,
  },

  candidateDataTitle: {
    display: 'flex',
    gap: 6,
    alignItems: 'flex-end',
    justifyContent: 'left',
    margin: '1.2rem 0',
    color: '#737372',
    fontSize: '1.4rem',

    '& h5': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#737372',
    },
  },
  textOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  candidateDataSubtitle: {
    color: '#3A3939',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  candidateDataSubtitleAlternative: {
    color: '#3A3939',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  shopkeeperBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F1F1F1',
  },
  registrationBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F1F1F1',
    padding: 4,
    gap: 5,
  },
  shopkeeperData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shopkeeper: {
    maxWidth: 250,
    padding: '0 4px',
    borderRadius: 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: '#F1F1F1',
  },
  shopkeeperIcon: {
    width: '1.75rem',
    padding: '0 8px',
    cursor: 'pointer',
  },
  statusBox: {
    marginTop: '1rem',
    display: 'inline-grid',
  },
  refresh: {
    width: 190,
    height: 40,
    backgroundColor: '#CB2D1B',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderColor: 'transparent',
    marginTop: 16,
  },
  titleRefresh: {
    color: 'white',
    fontSize: 16,
  },
  titleColor: {
    color: '#525151',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '32px',
    border: 1,
    borderStyle: 'solid',
    padding: 8,
    paddingLeft: 4,
    borderColor: '#f1f1f1',
  },
  refreshSumitPF: {
    width: 315,
    height: 40,
    backgroundColor: '#CB2D1B',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderColor: 'transparent',
    marginTop: 16,
  },
  ucaPercentage: {
    zIndex: 2,
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    color: 'white',
    top: '-1px',
    fontWeight: 'bold',
    fontSize: 12,
  },
  lastCandidatesContainer: {
    borderBottom: '1px solid #D4D4D4',
    paddingBottom: 10,
    marginBottom: 25,
    marginTop: 20,
  },
  lastCandidatesMenu: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 600,
    cursor: 'pointer',
    marginBottom: 10,
  },
  lastCandidatesMenuTitle: {
    flex: 1,
    margin: '0 10px',
  },
  lastCandidatesMenuCount: {
    margin: '0 10px',
  },
  lastCandidatesMenuIcon: {
    marginBottom: 5,
  },
  lastCandidatesList: {
    marginTop: 10,
  },
  candidateContainer: {
    display: 'flex',
    backgroundColor: '#F1F1F1',
    borderRadius: 4,
    padding: 10,
    marginBottom: 10,
  },
  candidateItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'flex-start',
    fontSize: 15,
    flex: 1,
    marginRight: 5,
  },
  candidateItemDate: {
    color: '#737372',
  },
  candidateItemStatusLabel: {
    color: '#3A3939',
    fontWeight: 600,
  },
  candidateItemStatus: {
    backgroundColor: '#ABDEF5',
    padding: 5,
    borderRadius: 4,
  },
  seeCandidateButton: {
    cursor: 'pointer',
    backgroundColor: '#FDC5B9',
    padding: 10,
    borderRadius: 9999,
    alignSelf: 'center',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#3A3939',
  },
  seeAllButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#3A3939',
    gap: 10,
    fontSize: 15,
    marginBottom: 5,
    fontWeight: 600,
    marginTop: 15,
    textDecoration: 'none',
  },
  resendButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    fontSize: 16,
  },
  modalBackground: {
    inset: 0,
    position: 'absolute',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    backgroundColor: 'white',
    width: '60%',
    borderRadius: 4,
    margin: 'auto',
    fontWeight: 400,
    fontSize: 16,
  },
  modalTitle: {
    color: '#333333',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 0,
  },
  modalcontent: {
    borderBottom: '1px solid #F1F1F1',
    padding: 15,
    position: 'relative',
  },
  modalCheckbox: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    color: '#6B6B6B',
  },
  modalCheckboxLabel: {
    marginTop: 2,
  },
  modalControls: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
    padding: 15,
  },
  modalCancelButton: {
    fontWeight: 400,
    color: '#525151',
    border: 'none',
    backgroundColor: 'white',
    fontSize: 18,
  },
  modalConfirmButton: {
    fontWeight: 600,
    color: '#F1F1F1',
    backgroundColor: '#DE2817',
    padding: '8px 16px',
    border: 'none',
    borderRadius: 9999,
    fontSize: 18,
    minWidth: 220,
    height: 40,
  },
  modalCloseButton: {
    color: '#9E9E9E',
    fontSize: 16,
    padding: 4,
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
  },
  newconStatusIcon: {
    fontSize: 10,
    marginLeft: 10,
    marginRight: 5,
  },
}))

import api from 'store/utils/apiv2'

import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import {
  getListRequest,
  getListFailure,
  getListSuccess,
  getListRelatorioSuccess,
  getListRelatorioFailure,
  getHistoryListRequest,
  getHistoryListFailure,
  getHistoryListSuccess,
  getUnitsSuccess,
  getUnitsFailure,
  getMinutaSuccess,
  getMinutaFailure,
  getBanksSuccess,
  getBanksFailure,
  getArchivedListSuccess,
  getArchivedListFailure,
  sendCommentFailure,
  sendCommentSuccess,
  uploadPreviasSuccess,
  uploadPreviasFailure,
  uploadFileSuccess,
  uploadFileFailure,
  setDocumentsFailure,
  setDocumentsSuccess,
  setContratoSuccess,
  setContratoFailure,
  setContratoRequest,
  setProrrogarSuccess,
  setProrrogarFailure,
  setFilterListSuccess,
  setFilterListFailure,
  setRobotsSuccess,
  setRobotsFailure,
  setCancelContratoSuccess,
  setCancelContratoFailure,
  setCancelTermConfidentialitySuccess,
  setCancelTermConfidentialityFailure,
  setResendTermConfidentialitySuccess,
  setResendTermConfidentialityFailure,
  setRegisterUCASuccess,
  setRegisterUCAFailure,
  refreshDocsFailure,
  refreshDocsRequest,
  updateUserPjSuccess,
  updateUserPjFailure,
  arquivarPreviaSuccess,
  arquivarPreviaFailure,
  finishPreviaFailure,
  finishPreviaSuccess,
  postAddToBlacklistSuccess,
  postAddToBlacklistFailure,
  getUserDetailSuccess,
  getUserDetailFailure,
  getUserDetailRequest,
  sendAprovarReprovarDocumentoSuccess,
  sendDesistenciaSuccess,
  sendDesistenciaFailure,
  setMotivosPadraoSuccess,
  setMotivosPadraoFailure,
  setMotivosPadraoRequest,
  setBlacklistSuccess,
  setBlacklistFailure,
  setUploadSuccess,
  setUploadFailure,
  setBlacklistByIdSuccess,
  setBlacklistByIdFailure,
  setRemoveBlacklistSuccess,
  setRemoveBlacklistFailure,
  setBlacklistAddCommentSuccess,
  setBlacklistRequest,
  refreshDocsSuccessPj,
  refreshDocsSuccessPf,
  sendApproveAllDocsSuccess,
  sendApproveAllDocsFailure,
} from './actions'
import { PreviasTypes } from './types'
import { showToastr } from 'store/toastr/actions'
import Helpers from 'shared/helpers'

export function* handlerGetList(action: any) {
  const { body } = action.payload

  try {
    const res: any = yield call(api.post, `previas/list`, body)
    yield put(getListSuccess(res))
  } catch (err) {
    yield put(getListFailure())
  }
}

export function* handlerGetNotFiltredList(action: any) {
  const { body } = action.payload

  try {
    const res: any = yield call(api.post, `previas/list`, body)
    yield put(setFilterListSuccess(res))
  } catch (err) {
    yield put(setFilterListFailure())
  }
}
//Relatorio xls
export function* handlerGetListRelatorio() {
  try {
    const res: any = yield call(api.get, `previas/relatorio`)
    yield put(getListRelatorioSuccess(res))
  } catch (err) {
    yield put(getListRelatorioFailure())
  }
}

export function* handlerGetUserDetail(action: any) {
  const { id } = action.payload

  try {
    const previa: any = yield call(api.get, `/backoffice/previas/${id}`)
    yield put(getUserDetailSuccess(previa))
  } catch (err) {
    yield put(getUserDetailFailure())
  }
}

export function* handlerGetHistoryList(action: any) {
  const { id, page, size } = action.payload

  try {
    const res: any = yield call(api.get, `backoffice/previas/${id}/historic?page=${page}&&size=${size}`)
    yield put(getHistoryListSuccess(res))
  } catch (err) {
    yield put(getHistoryListFailure())
  }
}

// Envio comentário documentação reprovada
export function* handlerSendComment(action: any) {
  const { id, body, bodyContrato } = action.payload

  try {
    const res: any = yield call(api.post, `previas/${id}/historic`, body)
    yield put(sendCommentSuccess(res))
    if (bodyContrato && body.approved !== 'reprovado') {
      yield put(setContratoRequest(bodyContrato))
    }
    yield put(getHistoryListRequest(id, 1, 10))
    yield put(showToastr('enviado!', 'success'))
  } catch (err) {
    yield put(showToastr('algo deu errado.', 'error'))
    yield put(sendCommentFailure())
  }
}

export function* handlerDesistencia(action: any) {
  const { id, body } = action.payload

  try {
    const res: any = yield call(api.post, `previas/${id}/desistencia`, body)
    yield put(sendDesistenciaSuccess(res))
    yield put(getHistoryListRequest(id, 1, 10))
    yield put(showToastr('Desistência confirmada!', 'success'))
  } catch (err) {
    yield put(showToastr('algo deu errado.', 'error'))
    yield put(sendDesistenciaFailure())
  }
}

export function* handlerReverterDesistencia(action: any) {
  const { previaId } = action.payload

  try {
    yield call(api.post, `previas/desistencia/revert/${previaId}`)
    yield put(getHistoryListRequest(previaId, 1, 10))
    yield put(showToastr('Desistência revertida!', 'success'))
  } catch (err) {
    yield put(showToastr('algo deu errado.', 'error'))
    yield put(sendDesistenciaFailure())
  }
}

export function* handlerGetUnits(action: any) {
  const { id } = action.payload
  try {
    const res: any = yield call(api.get, `previas/serviceUnits?previa=${id}`)
    yield put(getUnitsSuccess(res))
  } catch (err) {
    yield put(getUnitsFailure())
  }
}

export function* handlerUploadPrevias(action: any) {
  const { body, id } = action.payload

  try {
    const res: any = yield call(api.put, `previas/${id}`, body)
    yield put(uploadPreviasSuccess(res))
    yield put(showToastr('Cadastro atualizado!', 'success'))
  } catch (err) {
    yield put(uploadPreviasFailure())
  }
}

export function* handlerSetDocuments(action: any) {
  const { id, body, type } = action.payload

  try {
    const res: any = yield call(api.put, `previas/${id}/checkdocuments?type=${type}`, body)
    yield put(setDocumentsSuccess(res))
    yield put(showToastr(`Documento ${body.approved ? 'aprovado' : 'recusado'}!`, 'success'))
  } catch (err) {
    yield put(setDocumentsFailure())
  }
}
// Adicionar consultas externas - análise PF
export function* handlerUploadFile(action: any) {
  const { previasId, file } = action.payload

  const formData = new FormData()
  formData.append('files', file)

  try {
    const res: any = yield call(api.post, `/backoffice/previas/${previasId}/upload/consultasexterna`, formData)
    yield put(getUserDetailRequest(previasId, false))
    yield put(uploadFileSuccess(res))
    if (previasId) {
      yield put(refreshDocsRequest(previasId))
    }
  } catch (err) {
    yield put(uploadFileFailure())
  }
}

export function* handlerDeleteFile(action: any) {
  const { previasId, fileId } = action.payload

  try {
    const res: any = yield call(api.delete, `/backoffice/previas/${previasId}/consultasexterna/${fileId}`)
    yield put(getUserDetailRequest(previasId, false))
    yield put(uploadFileSuccess(res))
    if (previasId) {
      yield put(refreshDocsRequest(previasId))
    }
  } catch (err) {
    yield put(uploadFileFailure())
  }
}

export function* handlerUpdateUserPj(action: any) {
  const { previaId, body } = action.payload

  try {
    const res: any = yield call(api.put, `previas/${previaId}/company`, body)
    yield put(updateUserPjSuccess(res))
    yield put(showToastr(`cadastro atualizado.`, 'success'))
  } catch (err) {
    yield put(updateUserPjFailure())
    yield put(showToastr(`algo deu errado.`, 'success'))
  }
}

export function* handlerSetContrato(action: any) {
  const { body, show } = action.payload

  try {
    const res: any = yield call(api.post, `camaracontratos/contrato/cadastrar`, body)
    yield put(setContratoSuccess(res))
    if (show) {
      yield put(showToastr('Contrato enviado', 'success'))
    }
  } catch (err) {
    yield put(setContratoFailure())
    yield put(showToastr('Algo deu errado.', 'error'))
  }
}

export function* handlerRefreshDocs(action: any) {
  const { id, type } = action.payload

  try {
    const res: any = yield call(api.get, `previas/documents/${id}?type=${type}`)
    if (type === 'pj') {
      yield put(refreshDocsSuccessPj(res))
    } else {
      yield put(refreshDocsSuccessPf(res))
    }
  } catch (err) {
    yield put(refreshDocsFailure())
  }
}

export function* handlerSetProrrogar(action: any) {
  const { previaId, body } = action.payload

  try {
    const res: any = yield call(api.put, `previas/${previaId}/prorrogacao`, body)
    yield put(setProrrogarSuccess(res))
    yield put(showToastr('Previa prorrogada', 'success'))
  } catch (err) {
    yield put(showToastr('Algo deu errado', 'error'))
    yield put(setProrrogarFailure())
  }
}

export function* handlerGetBanks() {
  try {
    const res: any = yield call(api.get, `consultas/bancos`)
    yield put(getBanksSuccess(res))
  } catch (err) {
    yield put(getBanksFailure())
    yield put(showToastr('Algo deu errado ao carregar os bancos', 'error'))
  }
}

export function* handlerGetMinuta(action: any) {
  const { body } = action.payload

  try {
    const res: any = yield call(api.post, `previas/minuta/contrato`, body)
    yield put(getMinutaSuccess(res))
  } catch (err) {
    yield put(getMinutaFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerSetRobots(action: any) {
  const { previaId, body } = action.payload

  try {
    const res: any = yield call(api.put, `previas/${previaId}/botcheck`, body)
    yield put(setRobotsSuccess(res))
  } catch (err) {
    yield put(setRobotsFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

// Arquivar ou Desarquivar uma prévia
export function* handlerArquivarPrevia(action: any) {
  const { previaId, tipo, body } = action.payload
  const bodyGetList = {
    page: 1,
    size: 25,
    text: '',
    bu: ['BU1', 'BU2', 'BU3', 'BU4', 'BU5'],
    status: '',
    sla: ['A Vencer', 'Vencendo', 'Em Atraso'],
  }
  try {
    const res: any = yield call(api.put, `previas/arquivar/${previaId}/${tipo}`, body)
    yield put(arquivarPreviaSuccess(res))
    yield put(showToastr('Previa arquivada', 'success'))
    yield put(getListRequest(bodyGetList))
  } catch (err) {
    yield put(arquivarPreviaFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

// Lista de todas as prévias arquivadas
export function* handlerArchivedList(action: any) {
  const { page, size } = action.payload
  try {
    const res: any = yield call(api.get, `/previas/arquivadas?page=${page}&size=${size}`)
    yield put(getArchivedListSuccess(res))
  } catch (err) {
    yield put(getArchivedListFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerSetCancelContrato(action: any) {
  const { body } = action.payload

  try {
    const res: any = yield call(api.post, `camaracontratos/termo/cancelar`, body)

    yield put(setCancelContratoSuccess(res))
    yield put(showToastr('Seu contrato foi cancelado', 'success'))
  } catch (err) {
    yield put(setCancelContratoFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerAddToBlacklist(action: any) {
  const body = action?.payload?.body
  try {
    const res: any = yield call(api.post, `/previas/blacklist`, body)

    yield put(postAddToBlacklistSuccess(res))
    yield put(showToastr('CPF inserido na Blacklist', 'success'))
  } catch (err) {
    yield put(postAddToBlacklistFailure())
    yield put(showToastr('O CPF ja está cadastrado na blacklist', 'error'))
  }
}

export function* handlerFinishPrevia(action: any) {
  const { id, body } = action.payload

  try {
    const res: any = yield call(api.post, `backoffice/previas/${id}/finalizar`, body)
    yield put(finishPreviaSuccess(res))
    yield put(showToastr('Processo de análise prévia finalizado.', 'success'))
  } catch (err) {
    yield put(finishPreviaFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerSetCancelTermConfidentiality(action: any) {
  const { body } = action.payload

  try {
    const res: any = yield call(api.post, `/camaracontratos/termo/cancelar`, body)
    yield put(setCancelTermConfidentialitySuccess(res))
    yield put(showToastr('O Termo foi cancelado.', 'success'))
  } catch (err) {
    yield put(setCancelTermConfidentialityFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerSetResendTermConfidentiality(action: any) {
  const { id } = action.payload

  try {
    // const res: any = yield call(api.post, `/camaracontratos/contrato/cadastrar`, data)
    const res: any = yield call(api.put, `/backoffice/previas/${id}/termo/reenviar`)
    yield put(setResendTermConfidentialitySuccess(res))
    yield put(showToastr('O Termo foi reenviado.', 'success'))
    yield put(getUserDetailRequest(id))
  } catch (err) {
    yield put(setResendTermConfidentialityFailure())
    yield put(showToastr('Algo deu errado', 'error'))
    yield put(getUserDetailFailure())
  }
}

export function* handlerSetRegisterUCA(action: any) {
  const { data } = action.payload

  try {
    const res: any = yield call(api.post, `/previas/retryuca/${data}`)
    yield put(setRegisterUCASuccess(res))
    yield put(showToastr('O cadastro UCA foi enviado.', 'success'))
  } catch (err) {
    yield put(setRegisterUCAFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handleAprovarReprovarDocumento(action: any) {
  const { id, body, type } = action.payload

  try {
    yield call(api.put, `/backoffice/previas/${id}/checkdocument`, body.body)
    yield put(sendAprovarReprovarDocumentoSuccess())
    //yield put(getUserDetailRequest(id, false))
    yield put(refreshDocsRequest(id, type))
  } catch (err) {
    yield put(setDocumentsFailure())
    yield put(sendAprovarReprovarDocumentoSuccess())
  }
}

export function* handleAprovarAnalisePF(action: any) {
  const { id } = action.payload

  try {
    yield call(api.put, `/backoffice/previas/${id}/aprovar/pf`)
    yield put(getUserDetailRequest(id, false))
  } catch (err) {
    yield put(getUserDetailFailure())
  }
}

export function* handleReprovarAnalisePF(action: any) {
  const { id, approved, descricao } = action.payload

  try {
    yield call(api.post, `/backoffice/previas/${id}/reprovar`, { approved, descricao })
    yield put(getUserDetailRequest(id, false))
  } catch (err) {
    yield put(getUserDetailFailure())
  }
}

export async function handleSendClickPasswordLog(id: string) {
  try {
    await api.put(`/backoffice/previas/${id}/seepassword`)
  } catch (err) {
    console.log('Ocorreu um erro ao enviar o click de senha')
  }
}

export function* handlerMotivosPadrao(action: any) {
  try {
    const res: any = yield call(api.get, `/previas/motivospadrao/all`)
    yield put(setMotivosPadraoSuccess(res))
  } catch (err) {
    yield put(setMotivosPadraoFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerCreateMotivoPadrao(action: any) {
  const { title, text, type } = action.payload.body
  try {
    yield call(api.post, `/previas/motivospadrao/create`, { title, text, type })
    yield put(showToastr('Motivo criado com sucesso', 'success'))
    yield put(setMotivosPadraoRequest({}))
  } catch (err) {
    yield put(setMotivosPadraoFailure())
    yield put(showToastr(`Ocorreu um erro.`, 'error'))
  }
}

export function* handlerEditMotivoPadrao(action: any) {
  const { title, text, type, id } = action.payload.body
  try {
    yield call(api.put, `/previas/motivospadrao/${id}`, { title, text, type })
    yield put(showToastr('Motivo editado com sucesso', 'success'))
    yield put(setMotivosPadraoRequest({}))
  } catch (err) {
    yield put(setMotivosPadraoFailure())
    yield put(showToastr(`Ocorreu um erro`, 'error'))
  }
}

export function* handlerDeleteMotivoPadrao(action: any) {
  const { id } = action.payload.body
  try {
    yield call(api.delete, `/previas/motivospadrao/${id}`)
    yield put(showToastr('Motivo excluído com sucesso', 'success'))
    yield put(setMotivosPadraoRequest({}))
  } catch (err) {
    yield put(setMotivosPadraoFailure())
    yield put(showToastr(`Ocorreu um erro`, 'error'))
  }
}

export function* handlerGetBlacklist(action: any) {
  try {
    const { search, page, dataInicial, dataFinal, status } = action.payload.body
    const res: any = yield call(
      api.get,
      `/previas/blacklist/all?cpfCnpj=${search}&page=${page}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&status=${status}`,
    )
    yield put(setBlacklistSuccess(res))
  } catch (err) {
    yield put(setBlacklistFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerUpload(action: any) {
  const { body } = action.payload
  const formData = new FormData()
  formData.append('file', body)
  try {
    const res: any = yield call(api.post, `/files/upload`, formData)
    yield put(setUploadSuccess(res))
  } catch (err) {
    yield put(setUploadFailure())
  }
}

export function* handlerAddBlacklist(action: any) {
  const { body } = action.payload
  try {
    const res: any = yield call(api.post, `/previas/blacklist/adicionar`, body)
    yield put(postAddToBlacklistSuccess(res))
    yield put(showToastr('CPF/CNPJ inserido na Blacklist', 'success'))
  } catch (err) {
    yield put(postAddToBlacklistFailure())
    yield put(showToastr('O CPF/CNPJ ja está cadastrado na blacklist', 'error'))
  }
  yield put(
    setBlacklistRequest({
      search: '',
      page: 1,
      dataInicial: '',
      dataFinal: '',
      status: 'todos',
    }),
  )
}

export function* handlerGetBlacklistById(action: any) {
  const { id } = action.payload
  try {
    const res: any = yield call(api.get, `/previas/blacklist/${id}`)
    yield put(setBlacklistByIdSuccess(res))
  } catch (err) {
    yield put(setBlacklistByIdFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerRemoveBlacklist(action: any) {
  const { body } = action.payload
  try {
    const res: any = yield call(api.put, `/previas/blacklist/remover`, body)
    yield put(setRemoveBlacklistSuccess(res))
    yield put(showToastr('CPF/CNPJ removido da blacklist', 'success'))
    window.location.reload()
  } catch (err) {
    yield put(setRemoveBlacklistFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function* handlerAddComment(action: any) {
  const { body } = action.payload
  try {
    const res: any = yield call(api.post, `/previas/blacklist/comentario/${body.id}`, body)
    yield put(setBlacklistAddCommentSuccess(res))
    yield put(showToastr('Comentário adicionado com sucesso', 'success'))
    window.location.reload()
  } catch (err) {
    yield put(setBlacklistFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

export function handleBlacklistDownload() {
  return api
    .request({
      url: '/previas/blacklist/relatorio',
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
        Authorization: `Bearer ${Helpers.getToken()}`,
      },
    })
    .then((response) => {
      const blob = new Blob([response as any])
      const href = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'relatorio_previas.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
    .catch(() => null)
}

export function* approveAllDocs(action: any) {
  const { id, type } = action.payload
  try {
    yield call(api.post, `/previas/documents/approveall/${id}?type=${type}`)
    yield put(refreshDocsRequest(id, type))
    yield put(showToastr('Todos os documentos foram aprovados', 'success'))
    yield put(sendApproveAllDocsSuccess())
  } catch (err) {
    yield put(sendApproveAllDocsFailure())
    console.log('Ocorreu um erro ao aprovar todos os documentos')
  }
}

export function sendToReanalysis(data: { idPrevia: string; description: string; confidencial: boolean }) {
  return api.post('/previas/reanalise_juridica', data)
}

export function denyReanalysis(data: { idPrevia: string; description: string; confidencial: boolean }) {
  return api.post('/previas/reanalise_juridica_negada', data)
}

export function approveReanalysis(data: { idPrevia: string }) {
  return api.post('/previas/reanalise_juridica_revert', data)
}

export function resendAvaAccess(idPrevia: string) {
  return api.get(`/backoffice/previas/${idPrevia}/credenciais_ava/reenviar`)
}

export function denyCandidate(data: { idPrevia: string; description: string; confidencial: boolean }) {
  return api.put(`/previas/${data.idPrevia}/negado`, { description: data.description, confidencial: data.confidencial })
}

export function downloadReport(idPrevia: string, type: 'pj' | 'pf') {
  return api
    .request({
      url: `/backoffice/previas/${idPrevia}/matricula_csv/${type}`,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
        Authorization: `Bearer ${Helpers.getToken()}`,
      },
    })
    .then((response) => {
      const blob = new Blob([response as any])
      const href = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `previas_${type}_${new Date().toLocaleDateString()}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
    .catch(() => null)
}

function* watchSagas(): Generator {
  yield takeLatest(PreviasTypes.GET_USER_DETAIL_REQUEST, handlerGetUserDetail)
  yield takeLatest(PreviasTypes.GET_LIST_REQUEST, handlerGetList)
  yield takeLatest(PreviasTypes.GET_UNITS_REQUEST, handlerGetUnits)
  yield takeLatest(PreviasTypes.GET_BANKS_REQUEST, handlerGetBanks)
  yield takeLatest(PreviasTypes.GET_MINUTA_REQUEST, handlerGetMinuta)
  yield takeLatest(PreviasTypes.GET_ARCHIVED_LISTS_REQUEST, handlerArchivedList)
  yield takeLatest(PreviasTypes.GET_HISTORY_LIST_REQUEST, handlerGetHistoryList)
  yield takeLatest(PreviasTypes.GET_LIST_RELATORIO_REQUEST, handlerGetListRelatorio)
  yield takeLatest(PreviasTypes.SET_ROBOTS_REQUEST, handlerSetRobots)
  yield takeLatest(PreviasTypes.SET_CONTRATO_REQUEST, handlerSetContrato)
  yield takeLatest(PreviasTypes.SET_PRORROGAR_REQUEST, handlerSetProrrogar)
  yield takeLatest(PreviasTypes.SET_DOCUMENTS_REQUEST, handlerSetDocuments)
  yield takeLatest(PreviasTypes.SET_REGISTER_UCA_REQUEST, handlerSetRegisterUCA)
  yield takeLatest(PreviasTypes.SET_FILTER_LIST_REQUEST, handlerGetNotFiltredList)
  yield takeLatest(PreviasTypes.SET_CANCEL_CONTRATO_REQUEST, handlerSetCancelContrato)
  yield takeLatest(PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_REQUEST, handlerSetCancelTermConfidentiality)
  yield takeLatest(PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_REQUEST, handlerSetResendTermConfidentiality)
  yield takeLatest(PreviasTypes.SEND_COMMENT_REQUEST, handlerSendComment)
  yield takeLatest(PreviasTypes.SEND_DESISTENCIA_REQUEST, handlerDesistencia)
  yield takeLatest(PreviasTypes.UPLOAD_PREVIAS_REQUEST, handlerUploadPrevias)
  yield takeLatest(PreviasTypes.UPLOAD_FILE_REQUEST, handlerUploadFile)
  yield takeLatest(PreviasTypes.UPDATE_USER_PJ_REQUEST, handlerUpdateUserPj)
  yield takeLatest(PreviasTypes.REFRESH_DOCS_REQUEST, handlerRefreshDocs)
  yield takeLatest(PreviasTypes.ARQUIVAR_PREVIA_REQUEST, handlerArquivarPrevia)
  yield takeLatest(PreviasTypes.FINISH_PREVIA_REQUEST, handlerFinishPrevia)
  yield takeLatest(PreviasTypes.POST_ADD_BLACK_LIST_REQUEST, handlerAddToBlacklist)
  yield takeLatest(PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_REQUEST, handleAprovarReprovarDocumento)
  yield takeLatest(PreviasTypes.DELETE_FILE_CONSULTA_EXTERNA_REQUEST, handlerDeleteFile)

  // Novas rotas
  yield takeLatest(PreviasTypes.APROVAR_ANALISE_PF_REQUEST, handleAprovarAnalisePF)
  yield takeLatest(PreviasTypes.REPROVAR_ANALISE_PF_REQUEST, handleReprovarAnalisePF)
  yield takeLatest(PreviasTypes.REVERTER_DESISTENCIA, handlerReverterDesistencia)

  yield takeLatest(PreviasTypes.SET_MOTIVOS_PADRAO_REQUEST, handlerMotivosPadrao)
  yield takeLatest(PreviasTypes.CREATE_MOTIVO_PADRAO, handlerCreateMotivoPadrao)
  yield takeLatest(PreviasTypes.EDIT_MOTIVO_PADRAO, handlerEditMotivoPadrao)
  yield takeLatest(PreviasTypes.DELETE_MOTIVO_PADRAO, handlerDeleteMotivoPadrao)

  yield takeLatest(PreviasTypes.SET_BLACKLIST_REQUEST, handlerGetBlacklist)
  yield takeLatest(PreviasTypes.SET_BLACKLIST_UPLOAD_REQUEST, handlerUpload)
  yield takeLatest(PreviasTypes.SET_ADD_BLACKLIST_REQUEST, handlerAddBlacklist)

  yield takeLatest(PreviasTypes.SET_BLACKLIST_BY_ID_REQUEST, handlerGetBlacklistById)
  yield takeLatest(PreviasTypes.SET_REMOVE_BLACKLIST_REQUEST, handlerRemoveBlacklist)
  yield takeLatest(PreviasTypes.SET_BLACKLIST_ADD_COMMENT, handlerAddComment)
  yield takeLatest(PreviasTypes.BLACKLIST_DOWNLOAD_FILE_REQUEST, handleBlacklistDownload)

  yield takeLatest(PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_REQUEST, approveAllDocs)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}

/* eslint-disable @typescript-eslint/camelcase */
import { AntecipayTypes, IAntecipayState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IAntecipayState = {
  list: {
    loading: false,
    data: [],
    error: '',
  },
  contratos: {
    loading: false,
    data: [],
    error: '',
    pg: 1,
    pgs: 1,
    limit: 20,
    contrato: '',
    status: '',
    tipo: '',
    tipos: [],
  },
  item: {
    created_at: '',
    email: '',
    id: '',
    envelope: '',
    tipo: '',
    status: '',
    download: '',
    contrato: '',
    assinaturas: [],
  },
  limite: {
    loading: false,
    values: {
      cdComissionado: '',
      percentual: 0,
      vlComissaoAnteci: 0,
      cnpj: '',
      parcela: 0,
      vlComissaoFutura: 0,
      idGrupo: '',
    },
    lance: {
      valorMin: 0,
      valorMax: 0,
    },
    avaliable: false,
    success: false,
  },
}

export const antecipayReducer = (
  state: IAntecipayState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IAntecipayState => {
  switch (action.type) {
    case AntecipayTypes.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case AntecipayTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          loading: false,
        },
      }
    case AntecipayTypes.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case AntecipayTypes.GET_LIST_CONTRACTS_REQUEST:
      return {
        ...state,
        contratos: {
          ...state.contratos,
          loading: true,
          pg: action.payload.pg,
          pgs: action.payload.pgs,
          limit: action.payload.limit,
          contrato: action.payload.contrato,
          tipo: action.payload.tipo,
          status: action.payload.status,
        },
      }
    case AntecipayTypes.GET_LIST_CONTRACTS_SUCCESS:
      return {
        ...state,
        contratos: {
          ...state.contratos,
          data: action.payload.data.rows,
          pgs: action.payload.data.pgs,
          loading: false,
        },
      }
    case AntecipayTypes.GET_LIST_CONTRACTS_FAILURE:
      return {
        ...state,
        contratos: {
          ...state.contratos,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case AntecipayTypes.GET_LIST_CONTRACTS_TYPES_SUCCESS:
      return {
        ...state,
        contratos: {
          ...state.contratos,
          tipos: action.payload.data,
        },
      }
    case AntecipayTypes.GET_CONTRACT_REQUEST:
      return {
        ...state,
        item: {
          created_at: '',
          email: '',
          id: '',
          envelope: '',
          tipo: '',
          status: '',
          download: '',
          contrato: '',
          assinaturas: [],
        },
      }
    case AntecipayTypes.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
      }
    case AntecipayTypes.GET_LIMITE_REQUEST:
      return {
        ...state,
        limite: {
          ...state.list,
          loading: true,
          avaliable: false,
          success: false,
          values: {
            cdComissionado: '',
            percentual: 0,
            vlComissaoAnteci: 0,
            cnpj: '',
            parcela: 0,
            vlComissaoFutura: 0,
            idGrupo: '',
          },
          lance: {
            valorMin: 0,
            valorMax: 0,
          },
        },
      }
    case AntecipayTypes.GET_LIMITE_FAILURE:
      return {
        ...state,
        limite: {
          ...state.limite,
          avaliable: false,
          success: false,
          values: {
            cdComissionado: '',
            percentual: 0,
            vlComissaoAnteci: 0,
            cnpj: '',
            parcela: 0,
            vlComissaoFutura: 0,
            idGrupo: '',
          },
          lance: {
            valorMin: 0,
            valorMax: 0,
          },
          loading: false,
        },
      }
    case AntecipayTypes.GET_LIMITE_SUCCESS:
      return {
        ...state,
        limite: {
          ...state.limite,
          avaliable: action.payload.data.avaliable,
          values: action.payload.data.values,
          lance: action.payload.data.lance,
          loading: false,
          success: true,
        },
      }
    default:
      return state
  }
}
